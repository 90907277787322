import products from '../../../sharedTypes/products';
import constants from '../../constants';

const { states } = constants;

export default {
  product: products.PAYMENTS,
  state: states.EMAIL_AUTH_REQUIRED,
  stateContext: constants.state_context.CONTACT_AUTH,
  frozenConfig: {
    payments: {
      contactMfaCounter: 0,
      paymentMfaCounter: 1,
    },
    widget: {
      entityIdSelected: 'cl_banco_scotiabank',
    },
    config: {
      organizationId: 'org_DjWBZ0XFYOXZ48Nm',
      mfaConfig: {
        email: {
          authLength: 6,
        },
      },
    },
  },
};
